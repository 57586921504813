import React, { FC } from "react";
import { Form, useFormikContext } from "formik";
import {
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  Switch,
  Typography,
  Dialog
} from "@mui/material";
import { useState } from "react";

import { Case, Subject } from "auditaware-types";

import UsStateSelect from "../../shared/Form/UsStateSelect";
import SearchIcon from "@mui/icons-material/Search";
import ContactsSearch from "./ContactsSearch";
import GridTextField from "../../shared/GridTextField";

type CaseFormProps = {
    closeDrawer: () => void;
    subject: Subject;
    isEditMode: boolean;
};

type ContactFieldsProps = {
    prefix: string;
    title: string;
    contactAccess: boolean;
    handleSwitchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const SectionDivider = ({ title }: { title: string }) => (
  <Grid item sm={12} my={1}>
    <Divider>
      <Chip label={title} />
    </Divider>
  </Grid>
);

const ContactFields = ({
  prefix,
  title,
  contactAccess,
  handleSwitchChange
}: ContactFieldsProps) => {
  return (
    <>
      <SectionDivider title={`${title} Information`} />
      <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
        <Typography variant="subtitle1">
            Case Access:
        </Typography>
        <Switch
          checked={contactAccess}
          onChange={handleSwitchChange}
          color="primary"
          inputProps={{ "aria-label": "Case Access Switch" }}
        />
        <Typography variant="subtitle1" ml={1}>
          {contactAccess ? "Yes" : "No"}
        </Typography>
      </Grid>
      <GridTextField name={`${prefix}.primaryName`} label="First Name" xs={12} md={6} />
      <GridTextField name={`${prefix}.secondaryName`} label="Last Name" xs={12} md={6} />
      <GridTextField name={`${prefix}.address`} xs={12} />
      <GridTextField name={`${prefix}.city`} xs={12} lg={6} />
      <Grid item xs={12} md={8} lg={4}>
        <UsStateSelect name={`${prefix}.stateAbbr`} />
      </Grid>
      <GridTextField name={`${prefix}.zipCode`} xs={12} md={4} lg={2} />
      <GridTextField name={`${prefix}.phoneNumber`} xs={12} md={6} />
      <GridTextField name={`${prefix}.email`} xs={12} md={6} />
    </>
  );
};

const ContactsForm: FC<CaseFormProps> = ({ closeDrawer }) => {
  const { isSubmitting } = useFormikContext<Case>();
  const [primaryContactAccess, setPrimaryContactAccess] = useState(false);
  const [secondaryContactAccess, setSecondaryContactAccess] = useState(false);
  const [open, setOpen] = useState(false);

  const handlePrimarySwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPrimaryContactAccess(event.target.checked);
  };

  const handleSecondarySwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSecondaryContactAccess(event.target.checked);
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Form>
      <Grid container spacing={2}>
        <Grid item xs={12} display="flex" justifyContent="center" mb={1}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<SearchIcon />}
            sx={{ borderRadius: 24 }}
            onClick={handleOpen}
          >
          Search for Contacts
          </Button>
        </Grid>
        <Dialog
          open={open}
          onClose={handleClose}
          maxWidth="lg"
          fullWidth
        >
          <ContactsSearch
            prefix="primaryContact"
            title="PrimaryContact"
            contactAccess={primaryContactAccess}
          />
        </Dialog>
        <ContactFields
          prefix="primaryContact"
          title="Primary Contact"
          contactAccess={primaryContactAccess}
          handleSwitchChange={handlePrimarySwitchChange}
        />
        <ContactFields
          prefix="secondaryContact"
          title="Secondary Contact"
          contactAccess={secondaryContactAccess}
          handleSwitchChange={handleSecondarySwitchChange}
        />
      </Grid>
      <Box
        sx={{
          position: "fixed",
          bottom: 0,
          right: 0,
          width: "420px",
          borderTop: "1px solid #e0e0e0",
          display: "flex",
          justifyContent: "space-between",
          padding: ".5rem",
          zIndex: 1,
        }}
      >
        <Button
          type="submit"
          variant="contained"
          size="large"
          color="success"
          disabled={isSubmitting}
          fullWidth={true}
        >
                    Save
        </Button>
        <Button
          variant="outlined"
          size="large"
          color="secondary"
          disabled={isSubmitting}
          fullWidth={true}
          sx={{ ml: 2 }}
          onClick={closeDrawer}
          type="reset"
        >
                    Cancel
        </Button>
      </Box>
    </Form>
  );
};

export default ContactsForm;
