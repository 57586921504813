import { FC } from "react";
import {
  Box,
  Button,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  IconButton,
  Paper,
  Grid,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import { grey } from "@mui/material/colors";

import { useAuth } from "@reasongcp/react-fire-sub";
import { Note } from "auditaware-types";
import { CustomDrawer, useDrawer } from "auditaware-ui";

import EditNote from "../Note/EditNote";
import CreateNote from "../Note/CreateNote";
import InitialsAvatar from "../../shared/InitialsAvatar";
import useCoercedDateScalar from "../../../hooks/useCoercedDateScalar";

interface CaseNotesProps {
    notes: Note[];
    caseId: string;
}

const CaseNote: FC<{ note: Note }> = ({ note }) => {
  const { toggleDrawer } = useDrawer();
  const { author, text } = note;
  const safeUpdatedAt = useCoercedDateScalar(note.updatedAt);

  const user = useAuth();
  const canEdit = user?.uid === author.uid;

  return (
    <ListItem
      alignItems="flex-start"
      divider={true}
      sx={{
        alignItems: "center",
      }}
    >
      <ListItemAvatar>
        <InitialsAvatar user={author} />
      </ListItemAvatar>
      <ListItemText
        primary={
          <Box
            mb={1}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h6">{author.displayName || author.email}</Typography>
            <Typography variant="subtitle2" color="text.secondary" component="div">
              <small>{safeUpdatedAt.toLocaleString()}</small>
            </Typography>
          </Box>
        }
        secondary={
          <Typography
            variant="body2"
            component="div"
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {text}
          </Typography>
        }
      />
      {canEdit && (
        <IconButton
          aria-label="Edit Note"
          title="Edit Note"
          onClick={() => toggleDrawer(`editNote-${note.id}`)}
        >
          <EditIcon color="primary" fontSize="small" />
        </IconButton>
      )}
      <CustomDrawer
        title="Edit Note"
        content={<EditNote note={note} />}
        drawerId={`editNote-${note.id}`}
      />
    </ListItem>
  );
};

const Notes: FC<{ notes: Note[] }> = ({ notes }) => {
  const sortedNotes = [...notes].sort(
    (a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
  );
  if (sortedNotes.length === 0) {
    return (
      <List>
        <Typography component="p" marginTop="8px" marginBottom="8px">
                    No notes available
        </Typography>
      </List>
    );
  }

  return (
    <List>
      {sortedNotes.map((note, idx) => {
        return <CaseNote key={idx} note={note} />;
      })}
    </List>
  );
};

const CaseNotes: FC<CaseNotesProps> = ({ notes, caseId }) => {
  const { toggleDrawer } = useDrawer();

  const overviewBgColor = {
    borderRadius: 1,
    // my: 1,
    bgcolor: (theme: any) => (theme.palette.mode === "light" ? grey[100] : grey[800]),
    px: 2,
  };

  return (
    <Grid item xs md={12} lg={12} sx={{ marginBottom: "15px" }}>
      <Paper>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            justifyContent: "space-between",
            borderBottom: "1px solid #e0e0e0",
          }}
        >
          <Box>
            <Typography component="h1" variant="h5" padding="16px">
                            Notes
            </Typography>
          </Box>
          <Box component="span" mr={1}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              sx={{ borderRadius: 24 }}
              onClick={() => toggleDrawer("createNote")}
            >
                            Add Note
            </Button>
            <CustomDrawer
              title="Add Note"
              content={<CreateNote caseId={caseId} />}
              drawerId={"createNote"}
            />
          </Box>
        </Box>
        <Box
          sx={overviewBgColor}
          paddingRight={"16px"}
          paddingBottom={"8px"}
          paddingTop={"8px"}
        >
          <Notes notes={notes} />
        </Box>
      </Paper>
    </Grid>
  );
};

export default CaseNotes;
