import { FC, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import {
  Box,
  Grid,
  Paper,
  Typography,
  Button,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { styled } from "@mui/material/styles";

import { Drawer, useDrawerControls } from "auditaware-ui";

import { useLoadCase } from "../../../hooks/cases";
import CaseOverview from "./CaseOverview";
import EditCase from "./EditCase";
import CaseNotes from "./CaseNotes";
import CaseAttachments from "./CaseAttachments";
import { List, Item } from "../../shared/List";
import useFormattedDate from "../../../hooks/useFormattedDate";

const overviewBgColor = {
  borderRadius: 1,
  my: 1,
  bgcolor: (theme: any) =>
    theme.palette.mode === "light" ? grey[100] : grey[800],
  px: 2,
};

const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.main,
  textDecoration: "none",
  "&:hover": {
    textDecoration: "underline",
  },
}));

const Case: FC = () => {
  const { caseId = null } = useParams();
  const [
    loadCase,
    { error, loading, case: caseData },
  ] = useLoadCase();

  useEffect(() => {
    if (!caseId) return;

    loadCase({ variables: { id: caseId } }).then();
  }, [caseId, loadCase]);

  const feeInvoicedDate = useFormattedDate(
    caseData?.feeInvoicedOn,
  );

  const [
    drawerProps,
    openDrawer,
    closeDrawer,
  ] = useDrawerControls({ title: "Edit Case" });

  if (error || loading || !caseData || !caseId) return null;

  const { subject } = caseData;

  return (
    <>
      <Grid item xs md={12} lg={12} sx={{ marginBottom: "15px" }}>
        <Paper>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              justifyContent: "space-between",
              p: 2,
              borderBottom: "1px solid #e0e0e0",
            }}
          >
            <Box>
              <Typography
                component="h1"
                variant="h5"
                sx={{ fontWeight: 500 }}
                gutterBottom
              >
                <StyledLink to={`/subjects/${subject.id}/cases`}>
                  {subject.contact.primaryName}
                </StyledLink>
              </Typography>
              {subject.location && (
                <Typography variant="body2">
                  {subject.location.address},{" "}
                  {subject.location.city},{" "}
                  {subject.location.stateAbbr}{" "}
                  {subject.location.zipCode}
                  <br />
                      Parcel ID: {subject.location.parcelId}
                </Typography>
              )}
            </Box>

            <Box component="span" mr={1}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                aria-label="Edit Case"
                title="Edit Case"
                sx={{ borderRadius: 24 }}
                onClick={openDrawer}
              >
                  Edit Case
              </Button>
              <Drawer {...drawerProps}>
                <EditCase
                  caseData={caseData}
                  subject={subject}
                  closeDrawer={closeDrawer}
                />
              </Drawer>
            </Box>
          </Box>
        </Paper>
      </Grid>

      <CaseOverview caseData={caseData} />
      <CaseNotes caseId={caseId} notes={caseData.notes} />
      <CaseAttachments caseId={caseId} attachments={caseData.attachments} />

      <Grid item xs md={12} lg={12} sx={{ marginBottom: "15px" }}>
        <Paper>
          <Typography component="h1" variant="h5" padding="16px">
              Fees
          </Typography>
          <Box sx={overviewBgColor} paddingRight={"16px"} paddingBottom={"16px"}>
            <Grid container spacing={2}>
              <Grid item md lg={6}>
                <List>
                  <Item primary="Fee Amount" secondary={caseData.feeAmount || ""} />
                  <Item primary="Fee Invoiced" secondary={feeInvoicedDate || ""} />
                  <Item primary="Fee Size" secondary={caseData.feeSize} />
                </List>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Grid>
    </>
  );
};

export default Case;
