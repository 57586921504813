import React, { FC } from "react";
import { Box, Grid, Typography, Paper, Button } from "@mui/material";
import { grey } from "@mui/material/colors";
import { styled } from "@mui/material/styles";

import { Case, CaseType, Contact } from "auditaware-types";

import { List, Item } from "../../shared/List";
import { CaseIcon } from "../../shared/AllListGrid/TypeAvatar";
import { EditIcon } from "../../../lib/appTypeIcons";
import { PrettyStatus } from "../../shared/PrettyStatus";
import Address from "../../shared/Address";
import useFormattedDate from "../../../hooks/useFormattedDate";
import { caseColorFromTheme } from "../../../lib/appTypeColors";
import { Drawer, useDrawerControls } from "auditaware-ui";
import EditContacts from "./EditContacts";

interface CaseOverviewProps {
  caseData: Case;
}

const CaseTypeBox = styled("span", { shouldForwardProp: (prop) => prop === "children" })<{
  caseType: CaseType | null;
}>(({ theme, caseType }) => ({
  display: "flex",
  alignItems: "center",
  color: caseColorFromTheme(theme, caseType),
}));

const overviewBgColor = {
  borderRadius: 1,
  my: 1,
  bgcolor: (theme: any) => (theme.palette.mode === "light" ? grey[100] : grey[800]),
  px: 2,
};

const ContactInfo: FC<{ contact: Contact; title: string }> = ({ contact, title }) => {
  const name = `${contact.primaryName} ${contact.secondaryName}`;

  return (
    <Grid item md lg={6}>
      <Typography variant="subtitle2" gutterBottom>
        {title}
      </Typography>
      <List>
        <Item primary="Name" secondary={name} />
        <Item primary="Email" secondary={contact.email} />
        <Item primary="Phone" secondary={contact.phoneNumber} />
        <Item primary="Address">
          <Address {...contact} />
        </Item>
        <Item primary="Portal Account"></Item>
        <Item primary="Case Access"></Item>
      </List>
    </Grid>
  );
};

const CaseOverview: FC<CaseOverviewProps> = ({ caseData }) => {
  const scheduledOnDate = useFormattedDate(caseData.scheduledOn);
  const confirmationSentOnDate = useFormattedDate(caseData.confirmationSentOn);
  const documentsReceivedOnDate = useFormattedDate(caseData.documentsReceivedOn);
  const resultsLetterSentOnDate = useFormattedDate(caseData.resultsLetterSentOn);
  const reviewCompletedOnDate = useFormattedDate(caseData.reviewCompletedOn);

  if (!caseData) return null;

  const { assignee } = caseData;
  const safeAssigneeName = assignee?.displayName || assignee?.email;
  const safeReviewedByName =
      caseData.reviewedBy?.displayName || caseData.reviewedBy?.email;

  const [
    drawerProps,
    openDrawer,
    closeDrawer,
  ] = useDrawerControls({ title: "Edit Contacts" });

  const { subject } = caseData;

  const contactInfoStyles = {
    ...overviewBgColor,
    "& .MuiTypography-subtitle2": {
      fontWeight: "bold",
      textDecoration: "underline",
    },
  };

  return (
    <>
      <Grid item xs md={12} lg={12} sx={{ marginBottom: "15px" }}>
        <Paper>
          <Typography component="h1" variant="h5" padding="16px">
              Info
          </Typography>
          <Box sx={overviewBgColor}>
            <Grid container spacing={2}>
              <Grid item md lg={6}>
                <List>
                  <Item primary="Assignee" secondary={safeAssigneeName} />
                  <Item primary="Status">
                    <PrettyStatus status={caseData.status} />
                  </Item>
                  <Item primary="Case Type">
                    <CaseTypeBox caseType={caseData.caseType}>
                      <CaseIcon caseType={caseData.caseType} />
                        &nbsp;
                      {caseData.caseType}
                    </CaseTypeBox>
                  </Item>
                  <Item primary="Cycle" secondary={caseData.cycle} />
                </List>
              </Grid>
              <Grid item md lg={6}>
                <List>
                  <Item primary="Scheduled On" secondary={scheduledOnDate} />
                  <Item primary="Confirmation Sent" secondary={confirmationSentOnDate} />
                  <Item
                    primary="Documents Received"
                    secondary={documentsReceivedOnDate}
                  />
                  <Item primary="Parcel ID" secondary={caseData?.location?.parcelId} />
                  <Item primary="Location">
                    <Address {...caseData.location} />
                  </Item>
                </List>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Grid>

      <Grid item xs md={12} lg={12} sx={{ marginBottom: "15px" }}>
        <Paper>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            padding="6px"
          >
            <Typography component="h1" variant="h5" padding="16px">
                Contacts
            </Typography>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              aria-label="Edit Contacts"
              title="Edit Contacts"
              sx={{ borderRadius: 24 }}
              startIcon={<EditIcon />}
              onClick={openDrawer}
            >
                Edit
            </Button>
            <Drawer {...drawerProps}>
              <EditContacts
                caseData={caseData}
                subject={subject}
                closeDrawer={closeDrawer}
              />
            </Drawer>
          </Box>
          <Box sx={contactInfoStyles}>
            <Grid container spacing={2}>
              <ContactInfo title="Primary" contact={caseData.primaryContact} />
              <ContactInfo title="Secondary" contact={caseData.secondaryContact} />
            </Grid>
          </Box>
        </Paper>
      </Grid>

      <Grid item xs md={12} lg={12} sx={{ marginBottom: "15px" }}>
        <Paper>
          <Typography component="h1" variant="h5" padding="16px">
              Result Summary
          </Typography>
          <Box sx={overviewBgColor} paddingRight={"16px"} paddingBottom={"16px"}>
            <Grid container spacing={2}>
              <Grid item md lg={6}>
                <List>
                  <Item primary="Result" secondary={caseData.result?.name} />
                  <Item primary="Result Summary" secondary={caseData.resultSummary} />
                  <Item primary="Reviewed By" secondary={safeReviewedByName} />
                </List>
              </Grid>
              <Grid item md lg={6}>
                <List>
                  <Item
                    primary="Result Letter Sent"
                    secondary={resultsLetterSentOnDate}
                  />
                  <Item primary="Reviewed Completed" secondary={reviewCompletedOnDate} />
                </List>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Grid>
    </>
  );
};

export default CaseOverview;
